<template>
  <div>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.datefrom") }}</label>

            <flat-pickr
              v-model="filterSelect.dateFrom"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: `d-m-Y H${
                  filterSelect.api.value == 'v2/report/players-by-brands'
                    ? ''
                    : ':i'
                }`,
                enableTime: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.dateto") }}</label>
            <flat-pickr
              v-model="filterSelect.dateTo"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: `d-m-Y H${
                  filterSelect.api.value == 'v2/report/players-by-brands'
                    ? ''
                    : ':i'
                }`,
                enableTime: true,
                time_24hr: true,
              }"
            />
            <!-- <label class="mr-1 text-danger" v-if="filterSelect.dateFrom"
              >Rango maximo de 30 dias
            </label> -->
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1"
              >{{ $t("labels.currency") }}
            </label>
            <v-select
              v-model="filterSelect.currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="currencyOptions"
              :reduce="(currency) => currency.value"
              label="name"
              placeholder="Selecciona una Moneda"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1" for="customRadioInline1">WL </label>
            <v-select
              v-model="filterSelect.WL"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="wlOptions"
              label="name"
              :reduce="(wl) => wl._id"
              placeholder="Select WL"
              value=""
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1"
              >Clientes
            </label>
            <v-select
              v-model="filterSelect.customer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              @option:selected="selectCustomer"
              :reduce="(customer) => customer._id"
              placeholder="Seleccione un cliente"
              value=""
              multiple
              :loading="loadingDataCustomer"
            >
              <template #option="{ name }">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ name }}</span>
              </template>

              <!-- buttom select all  -->
              <template #list-header>
                <div class="d-flex justify-content-center my-1">
                  <b-button
                    size="sm"
                    @click="
                      filterSelect.customer = customerOptions.map((c) => c._id)
                    "
                    class="text-nowrap"
                    variant="primary"
                    v-if="
                      customerOptions.length > 1 &&
                      filterSelect.customer.length !== customerOptions.length
                    "
                  >
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="text-nowrap">Select All</span>
                  </b-button>
                  <b-button
                    size="sm"
                    @click="filterSelect.customer = []"
                    class="text-nowrap"
                    variant="primary"
                    v-if="filterSelect.customer.length > 0"
                  >
                    <feather-icon icon="SquareIcon" class="mr-50" />
                    <span class="text-nowrap">Unselect All</span>
                  </b-button>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          v-if="filterSelect.api.value == 'report/players-by-brands'"
        >
          <b-form-group>
            <label class="mr-1">Producto </label>
            <v-select
              v-model="filterSelect.product"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions"
              :clearable="false"
              label="name"
              :reduce="(product) => product._id"
              placeholder="Select product"
              value=""
            />
          </b-form-group>
        </b-col>

        <b-col md="4" v-else>
          <b-form-group>
            <label class="mr-1">Productos </label>
            <v-select
              v-model="filterSelect.products"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions"
              label="name"
              :reduce="(product) => product._id"
              placeholder="Select products"
              multiple
            >
              <template #list-header>
                <div class="d-flex justify-content-center my-1">
                  <b-button
                    size="sm"
                    @click="selectProducts('all')"
                    class="text-nowrap"
                    variant="primary"
                    v-if="showBotomSelectAllProducts"
                  >
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="text-nowrap">Select All</span>
                  </b-button>
                  <b-button
                    size="sm"
                    @click="selectProducts('none')"
                    class="text-nowrap"
                    variant="primary"
                    v-if="
                      !showBotomSelectAllProducts && productsOptions.length > 1
                    "
                  >
                    <feather-icon icon="SquareIcon" class="mr-50" />
                    <span class="text-nowrap">Unselect All</span>
                  </b-button>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group>
            <label class="mr-1">Api</label>
            <v-select
              v-model="filterSelect.api"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="apisOptions"
              placeholder="Seleccione la moneda"
              value="api"
              @input="filterSelect.products = []"
            />
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
          <b-col md="6">
            <b-form-group>
              <label class="mr-1 text-lg" for="customRadioInline1"
                >Jugador
              </label>
              <v-select
                v-model="filterSelect.playerName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="playerNameOptions"
                :clearable="false"
                label="username"
                :reduce="(player) => player._id"
                @search="
                  (search, loading) => {
                    this.searchPlayer(search, loading);
                  }
                "
                placeholder="Selecciona un Jugador"
              >
                <template #no-options="{ search, searching, loading }">
                  Escribe para buscar un jugador
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-col>

        <!-- <b-col md="4">
          <b-form-group :label="$t('labels.clients')">
            <v-select
              :value="$store.id"
              v-model="filterSelect.clients"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clientsOptions"
              label="name"
              @input="fetchDataReport"
              :reduce="(status) => status.id"
              placeholder="Selecciona un Cliente"
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button
            :disabled="loadingData"
            @click="inputSelect()"
            class="text-nowrap"
            variant="primary"
          >
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{
              $t("labels.search")
            }}</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- FILTER END -->
      <b-progress
        v-if="progressDownloadData > 0"
        :value="progressDownloadData"
        :max="100"
        show-progress
        animated
      ></b-progress>
    </b-card>
    <!-- TABLET START -->
    <b-card>
      <!-- <b-overlay :show="loadingData" variant="transparent"> -->

      <b-row class="mb-1">
        <b-col md="6"></b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end">
          <b-button
            @click="downloadFile()"
            class="text-nowrap"
            :variant="dataItems.length > 0 ? 'primary' : 'secondary'"
            :disabled="dataItems.length == 0"
          >
            <b-spinner small v-if="exportDataLoading"></b-spinner>
            <feather-icon
              v-if="!exportDataLoading"
              :icon="dataItems.length > 0 ? 'DownloadIcon' : 'XIcon'"
              class="mr-50"
            />
            <span v-if="!exportDataLoading" class="text-nowrap">{{
              $t("labels.export")
            }}</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        compactMode
        :columns="columns"
        :rows="dataItems"
        :group-options="{
          enabled: true,
        }"
        :pagination-options="{ enabled: false }"
        :sort-options="{ enabled: false }"
        :fixed-header="true"
        max-height="500px"
      >
        <div slot="emptystate" class="text-center">
          No hay datos para mostrar
        </div>
        <div slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'currency'">
            <p>{{ props.row.currency }}</p>
          </div>
          <div v-if="props.column.field == 'brand'">
            <p>{{ props.row.brand || "Sin Marca" }}</p>
          </div>
          <div v-if="props.column.field == 'bet'">
            <p>{{ props.row.bet | currency({ symbol: "" }) }}</p>
          </div>
          <div v-if="props.column.field == 'totalBet'">
            <p>{{ props.row.totalBet | currency({ symbol: "" }) }}</p>
          </div>
          <div v-if="props.column.field == 'profit'">
            <p>{{ props.row.profit | currency({ symbol: "" }) }}</p>
          </div>
          <div v-if="props.column.field == 'profitNet'">
            <p>{{ props.row.profitNet | currency({ symbol: "" }) }}</p>
          </div>
        </div>
        <div slot="table-header-row" slot-scope="props">
          <div :class="props.row.class">
            {{ props.row.label }}
          </div>
        </div>
      </vue-good-table>

      <hr />
      <h4>Totales</h4>
      <vue-good-table
        compactMode
        :columns="columnsTotal"
        :rows="dataItemsTotals"
        :pagination-options="{ enabled: false }"
        :sort-options="{ enabled: false }"
      >
        <div slot="emptystate" class="text-center">
          No hay datos para mostrar
        </div>
        <div slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'currency'">
            <p>{{ props.row.currency }}</p>
          </div>
          <div v-if="props.column.field == 'bet'">
            <p>{{ props.row.bet | currency({ symbol: "" }) }}</p>
            <p>{{ totalBetToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
          </div>
          <div v-if="props.column.field == 'totalBet'">
            <p>{{ props.row.totalBet }}</p>
          </div>
          <div v-if="props.column.field == 'profit'">
            <p>{{ props.row.profit | currency({ symbol: "" }) }}</p>
            <p>{{ profitToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
          </div>
          <div v-if="props.column.field == 'profitNet'">
            <p>{{ props.row.profitNet | currency({ symbol: "" }) }}</p>
            <p>
              {{ profitNetToUSD(props.row) | currency({ symbol: "" }) }} USD
            </p>
          </div>
        </div>
        <div slot="table-header-row" slot-scope="props">
          <div :class="props.row.class">
            {{ props.row.label }}
          </div>
        </div>
      </vue-good-table>

      <hr />
      <h4>Totales USD</h4>
      <vue-good-table
        compactMode
        :columns="columnsTotalUSD"
        :rows="dataItemsTotalsUSD"
        :pagination-options="{ enabled: false }"
        :sort-options="{ enabled: false }"
      >
        <div slot="emptystate" class="text-center">
          No hay datos para mostrar
        </div>
        <div slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'bet'">
            <p>{{ props.row.bet | currency({ symbol: "" }) }} USD</p>
          </div>
          <div v-if="props.column.field == 'profit'">
            <p>{{ props.row.profit | currency({ symbol: "" }) }}</p>
          </div>
          <div v-if="props.column.field == 'profitNet'">
            <p>{{ props.row.profitNet | currency({ symbol: "" }) }}</p>
          </div>
        </div>
        <div slot="table-header-row" slot-scope="props">
          <div :class="props.row.class">
            {{ props.row.label }}
          </div>
        </div>
      </vue-good-table>
    </b-card>
    <!-- TABLET END -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BTableSimple,
  BThead,
  BTfoot,
  BTh,
  BTr,
  BTd,
  BSpinner,
  BProgress,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import moment from "moment";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import { mapActions } from "vuex";
import currencies from "@/store/currencies";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    BTableSimple,
    BThead,
    BTh,
    BTfoot,
    BTr,
    BTd,
    BProgress,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    BSpinner,
    ToastificationContent,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    moment,
    axios,
  },
  data() {
    return {
      dataItemsWithoutFilter: [],
      progressDownloadData: 0,
      exportDataLoading: false,
      dataItems: [],
      apisOptions: [
        { label: "Api V1", value: "report/players-by-brands" },
        { label: "Api V2", value: "v2/report/players-by-brands" },
      ],
      currencyOptions: [
        { name: "Todas", value: "all" },
        { name: "ARS", value: "ARS" },
        { name: "BRL", value: "BRL" },
        { name: "CLP", value: "CLP" },
        { name: "COP", value: "COP" },
        { name: "EUR", value: "EUR" },
        { name: "MXN", value: "MXN" },
        { name: "MYR", value: "MYR" },
        { name: "PEN", value: "PEN" },
        { name: "PYG", value: "PYG" },
        { name: "USD", value: "USD" },
        { name: "UYU", value: "UYU" },
        { name: "VES", value: "VES" },
        { name: "XAF", value: "XAF" },
      ],
      playerNameOptions: [{ username: "Todos", _id: "all" }],
      columns: [
        { label: "Marca", field: "brand" },
        { label: "Moneda", field: "currency" },
        { label: "Apuestas", field: "totalBet" },
        { label: "Monto apostado", field: "bet" },
        { label: "Ganancia", field: "profit" },
        { label: "Ganancia neta", field: "profitNet" },
      ],
      totals: {},
      timeout: null,
      customerOptions: [],
      loadingDataCustomer: false,
      minDate: null,
      maxDate: null,
      currencies: [],
      filterSelect: {
        dateTo: null,
        dateFrom: null,
        customer: [],
        product: "all",
        products: [],
        currency: "all",
        playerName: "all",
        q: "",
        WL: "",
        api: { label: "Api V2", value: "v2/report/players-by-brands" },
      },
      productsOptions: [],
      wlOptions: [],
      loadingWlOptions: false,
      timeoutSearchPlayer: null,
      loadingData: false,
      timeOutSearchCustomer: null,
      columnsTotal: [
        { label: "Moneda", field: "currency" },
        { label: "Apuestas", field: "totalBet" },
        { label: "Monto apostado", field: "bet" },
        { label: "Ganancia", field: "profit" },
        { label: "Ganancia neta", field: "profitNet" },
      ],
      dataItemsTotals: [],
      columnsTotalUSD: [
        { label: "Monto apostado", field: "bet" },
        { label: "Ganancia", field: "profit" },
        { label: "Ganancia neta", field: "profitNet" },
      ],
      dataItemsTotalsUSD: [
        {
          bet: 0,
          profit: 0,
          profitNet: 0,
        },
      ],
    };
  },
  watch: {
    "filterSelect.customer": {
      handler(value) {
        const productsSet = new Set();
        value?.forEach((customer) => {
          const products =
            this.customerOptions?.find((c) => c._id === customer)?.products ||
            [];
          // validar que no se repitan los productos por el _id
          this.productsOptions = [...this.productsOptions, ...products].filter(
            (product, index, self) =>
              index === self.findIndex((t) => t._id === product._id)
          );
        });
      },
      deep: true,
    },
  },

  computed: {
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    userLoged() {
      return this.$store.getters["usersModule/userLoged"];
    },
    showBotomSelectAllProducts() {
      return (
        this.productsOptions?.length > 1 &&
        this.filterSelect.products?.length !== this.productsOptions?.length
      );
    },
  },
  created() {
    this.filterSelect.dateFrom = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD 00:00");
    this.filterSelect.dateTo = moment().format("YYYY-MM-DD 23:59");
    this.minDate = moment().subtract(29, "days").format("YYYY-MM-DD 00:00");
    this.maxDate = moment().format("YYYY-MM-DD 23:59");
    this.getExchangeRates();
  },
  async mounted() {
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    this.getCustomer();
    this.getProducts();
  },
  methods: {
    totalBetToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency === data.currency) {
          row.totalBetUSD = row.bet / data.rate;
          return row.totalBetUSD;
        }
      }
      row.totalBetUSD = row.bet;
      return row.totalBetUSD;
    },

    profitToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency === data.currency) {
          row.profitUSD = row.profit / data.rate;
          return row.profitUSD;
        }
      }
      // Si no se encuentra ninguna coincidencia, se asigna el valor original
      row.profitUSD = row.profit;
      return row.profitUSD;
    },

    profitNetToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency === data.currency) {
          row.profitNetUSD = row.profitNet / data.rate;
          return row.profitNetUSD;
        }
      }
      // Si no se encuentra ninguna coincidencia, se asigna el valor original
      row.profitNetUSD = row.profitNet;
      return row.profitNetUSD;
    },

    async getExchangeRates() {
      const res = await this.$store.dispatch("currenciesModules/getCurrencies");
      this.currencies = res;
    },
    sumCurrencies(data) {
      const currencyTotals = {};

      data.forEach((product) => {
        product.brands.forEach((brand) => {
          brand.currencies.forEach((currencyData) => {
            const { currency, bet, totalBet, profit, profitNet } = currencyData;

            if (!currencyTotals[currency]) {
              currencyTotals[currency] = {
                currency: currency,
                totalBet: 0,
                bet: 0,
                profit: 0,
                profitNet: 0,
              };
            }

            currencyTotals[currency].totalBet += totalBet;
            currencyTotals[currency].bet += bet;
            currencyTotals[currency].profit += profit;
            currencyTotals[currency].profitNet += profitNet;
          });
        });
      });

      return Object.values(currencyTotals);
    },
    ...mapActions("reports", ["downloadReportFileReception"]),

    async downloadFile() {
      this.exportDataLoading = true;
      const VUE_APP_GATEWAY = process.env.VUE_APP_GATEWAY;
      const customer = this.filterSelect.customer
        ? this.filterSelect.customer
        : [];
      const filter = {
        customersId: customer,
        dateFrom: moment(this.filterSelect.dateFrom)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        dateAt: moment(this.filterSelect.dateTo)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        product: this.filterSelect.product,
        currency: this.filterSelect.currency,
        player: this.filterSelect.playerName,
        WL: this.filterSelect.WL,
      };
      const finalUrl =
        VUE_APP_GATEWAY + "report/players-products-by-brands-reception";
      const format = ".xlsx";
      const customerName = this.customerOptions.find(
        (customer) => customer._id == filter.customerId
      );
      const fileTitle = `profit-players-product-${filter.product}-${
        this.filterSelect.customer == "all"
          ? "ALL"
          : customerName?.name.toUpperCase()
      }-(${filter.dateFrom + " - " + filter.dateAt})${format}`;

      this.downloadReportFileReception({
        filter: filter,
        fileTitle: fileTitle,
        url: finalUrl,
        dataItemsWithoutFilter: this.dataItemsWithoutFilter,
      })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("labels.errorDownloadFile"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.message}`,
            },
          });
        })
        .finally(() => (this.exportDataLoading = false));
    },
    async getReportDataAsyncProduct() {
      try {
        this.dataItems = [];
        this.dataItemsWithoutFilter = [];
        if (
          this.filterSelect.product == "all" &&
          this.filterSelect.api.value == "report/players-by-brands"
        ) {
          const allProducts = this.productsOptions.filter(
            (product) => product._id !== "all"
          );
          const totalProducts = allProducts.length;
          let count = 0;

          for (const product of allProducts) {
            count++;
            this.progressDownloadData = (count * 100) / totalProducts;
            await this.fetchDataReport({ product: product._id });
          }
          this.progressDownloadData = 0;
          return;
        } else {
          await this.fetchDataReport({ product: this.filterSelect.product });
        }
      } catch (error) {}
    },
    async getWlOptions() {
      try {
        this.loadingWlOptions = true;
        const customerSelected = this.customerOptions.find(
          (customer) => customer?._id == this.filterSelect.customer
        );
        const res = await this.$store.dispatch("reports/getWlOptions", {
          customerId: customerSelected?.customerId,
        });
        this.wlOptions = res.data;
        this.filterSelect.WL = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingWlOptions = false;
      }
    },
    async fetchDataReport({ product }) {
      try {
        this.loadingData = true;
        const customer =
          this.filterSelect.customer == "all" ? "" : this.filterSelect.customer;
        const params = {
          customersId: customer,
          dateFrom: moment(this.filterSelect.dateFrom)
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          dateAt: moment(this.filterSelect.dateTo)
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          product: product || this.filterSelect.product,
          products: this.filterSelect.products,
          currency: this.filterSelect.currency,
          player: this.filterSelect.playerName,
          urlApi: this.filterSelect.api.value,
        };
        const res = await this.$store.dispatch(
          "reports/fetchReportPlayersByBrands",
          params
        );
        const dataItemsWithoutFilter = res.data.data;
        this.dataItemsWithoutFilter = [
          ...this.dataItemsWithoutFilter,
          ...dataItemsWithoutFilter,
        ];

        const dataItems = res.data.data
          .map((d) => {
            if (d.product && d.brands) {
              return {
                html: false,
                label: d.product,
                mode: "span",
                children: d.brands.flatMap((b) => {
                  if (b.currencies) {
                    return b.currencies.map((c) => ({
                      brand: b.brand,
                      currency: c.currency,
                      bet: c.bet,
                      totalBet: c.totalBet,
                      profit: c.profit,
                      profitNet: c.profitNet,
                    }));
                  } else {
                    return [];
                  }
                }),
              };
            } else {
              return null;
            }
          })
          .filter(Boolean);

        this.dataItemsTotals = this.sumCurrencies(res.data.data);
        for (let data of this.dataItemsTotals) {
          if (this.totalBetToUSD(data) !== undefined) {
            this.dataItemsTotalsUSD[0]["bet"] =
              this.dataItemsTotalsUSD[0]["bet"] + this.totalBetToUSD(data);
            this.dataItemsTotalsUSD[0]["profit"] =
              this.dataItemsTotalsUSD[0]["profit"] + this.profitToUSD(data);
            this.dataItemsTotalsUSD[0]["profitNet"] =
              this.dataItemsTotalsUSD[0]["profitNet"] +
              this.profitNetToUSD(data);
          } else {
            this.dataItemsTotalsUSD[0]["bet"] =
              this.dataItemsTotalsUSD[0]["bet"] + data.bet;
            this.dataItemsTotalsUSD[0]["profit"] =
              this.dataItemsTotalsUSD[0]["profit"] + data.profit;
            this.dataItemsTotalsUSD[0]["profitNet"] =
              this.dataItemsTotalsUSD[0]["profitNet"] + data.profitNet;
          }
        }

        console.log("this.dataItemsTotalsUSD", this.dataItemsTotalsUSD);

        this.dataItems = [...this.dataItems, ...dataItems];
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async searchPlayer(search, loading) {
      if (!search) return;

      try {
        clearTimeout(this.timeoutSearchPlayer);
        this.timeoutSearchPlayer = setTimeout(async () => {
          loading(true);
          const customerSelect = this.customerOptions
            ? this.customerOptions.find(
                (item) => item._id == this.filterSelect.customer
              )
            : null;
          const customerId =
            this.filterSelect.customer == "all"
              ? ""
              : customerSelect
              ? customerSelect.customerId
              : "";
          const payload = {
            customerId: customerId,
            player: search,
          };
          const res = await this.$store.dispatch(
            "reports/fetchUsersPlayers",
            payload
          );
          this.playerNameOptions = res.data.users;
          this.playerNameOptions.unshift({ username: "Todos", _id: "all" });
        }, 500);
      } catch (error) {
      } finally {
        setTimeout(() => {
          loading(false);
        }, 900);
      }
    },
    inputSelect() {
      if (
        this.filterSelect.dateFrom !== "" &&
        this.filterSelect.dateTo !== "" &&
        this.filterSelect.playerName
      ) {
        // this.serverParams.page = 1;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getReportDataAsyncProduct();
        }, 500);
      }
    },
    selectProducts(data) {
      if (data == "all") {
        this.filterSelect.products = this.productsOptions.map(
          (product) => product._id
        );
      } else {
        this.filterSelect.products = [];
      }
    },

    async getCustomer() {
      try {
        this.loadingDataCustomer = true;
        const res = await this.$store.dispatch("customerModule/getCustomers", {
          paginate: {
            page: 1,
            perPage: 200,
            sortDesc: true,
          },
        });
        this.customerOptions = res.data.customers;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDataCustomer = false;
      }
    },
    async getCurrencys() {
      const res = await this.$store.dispatch("reports/getCurrencys");
    },
    async getProducts() {
      const res = await this.$store.dispatch("reports/getProducts");
      this.productsOptions = res.data;
    },
    selectCustomer(customer) {
      this.filterSelect.playerName = "all";
      this.getWlOptions();
    },
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
